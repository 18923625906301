<template>
  <v-app>
    <v-app-bar app v-if="user">
      <v-toolbar-title class>
        <router-link to="/" v-if="!isFuelCell">
          <v-img :src="require('@/assets/logo.png')" width="50" />
          <!-- <span>Blue Water Resolute</span> -->
        </router-link>
        <router-link to="/fuelcell/1" v-else>
          <v-img :src="require('@/assets/logo.png')" width="50" />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="isMDX || isTDX || isKDX || isReadOnly">
        <v-btn text to="/">Dashboard</v-btn>
        <v-btn text @click="logout">Logout</v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else-if="isCustomer">
        <v-btn text to="/">Dashboard</v-btn>
        <!-- <v-btn text to="/profile">Profile</v-btn> -->
        <v-btn text v-if="!previousCustomer" to="/add">Add Sensor</v-btn>
        <v-btn text @click="logout">Logout</v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else-if="isFuelCell">
        <v-btn text to="/fuelcell/1">Dashboard</v-btn>
        <v-btn text @click="logout">Logout</v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else-if="!isUser">
        <v-btn text to="/">Dashboard</v-btn>
        <!-- <v-btn text to="/fuelcell">Fuel Cells</v-btn> -->
        <!-- <v-btn text to="/users">Users</v-btn> -->
        <!-- <v-btn v-if="isSuper || isDistributor" text to="/stores">Stores</v-btn> -->
        <v-btn v-if="isSuper" text to="/locations">Locations</v-btn>
        <v-btn text to="/add">Add Sensor</v-btn>
        <!-- <v-btn text to="/testing">Testing</v-btn> -->
        <v-btn text @click="logout">Logout</v-btn>
      </v-toolbar-items>
      <v-toolbar-items v-else>
        <v-btn text to="/">Dashboard</v-btn>
        <v-btn text @click="logout">Logout</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-app-bar app elevation="1" v-else>
      <v-toolbar-title class="headline text-uppercase">
        <router-link to="/">
          <v-img :src="require('@/assets/logo.png')" width="50" />
        </router-link>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items v-if="$route.name === 'dashboard'">
        <v-btn text to="/manage">Add Sensor</v-btn>
      </v-toolbar-items>
    </v-app-bar>
    <v-main>
      <v-container fluid class="pa-5">
        <router-view :key="$route.fullPath" />
      </v-container>
    </v-main>
  </v-app>
</template>

<script>
import { mapState, mapGetters } from "vuex";
export default {
  name: "App",
  data: () => ({
    drawer: false,
    item: null,
  }),
  async created() {
    // this.$store.dispatch("sensor/allSensors");
  },
  computed: {
    ...mapState("user", ["user"]),
    ...mapGetters("user", [
      "isSuper",
      "isDistributor",
      "isStore",
      "isAdmin",
      "isUser",
      "isCustomer",
      "isLocation",
      "isMDX",
      "isTDX",
      "isKDX",
      "isFuelCell",
      "userDistributorName",
    ]),
    isReadOnly() {
      if (this.isLocation || this.isAdmin) return true
      else return false
    },
    previousCustomer() {
      return this.checkEmail(this.user.attributes.email);
    },
  },
  methods: {
    checkEmail(email) {
      if (
        email === "dairyqueen@mail.com" ||
        email === "cdshaw1958@gmail.com" ||
        email === "agordonfsf@rrv.net"
      ) {
        return true;
      } else return false;
    },
    logout() {
      this.$store.dispatch("user/logout");
    },
  },
};
</script>

<style lang="scss">
body {
  font-family: "Poppins", sans-serif;
  .v-toolbar__items {
    .v-btn__content {
      text-transform: capitalize;
      font-weight: bold;
    }
  }
}
.v-small-dialog__activator__content{
    display: block !important;
}
</style>
