import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store/index'
import vuetify from './plugins/vuetify';
import './plugins/axios';
import './plugins/qr';
import './plugins/amplify';
import './plugins/places';
import './plugins/mask';
import './plugins/csv';
import './plugins/moment'
import './plugins/sweetalert'

Vue.config.productionTip = false

new Vue({
  // async beforeCreate() {
  //   await this.$store.dispatch("user/checkUser")
  // },
  router,
  store,
  vuetify,
  render: h => h(App)
}).$mount('#app')