/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const onCreateLocation = /* GraphQL */ `
  subscription OnCreateLocation {
    onCreateLocation {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateLocation = /* GraphQL */ `
  subscription OnUpdateLocation {
    onUpdateLocation {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteLocation = /* GraphQL */ `
  subscription OnDeleteLocation {
    onDeleteLocation {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateStore = /* GraphQL */ `
  subscription OnCreateStore {
    onCreateStore {
      id
      name
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateStore = /* GraphQL */ `
  subscription OnUpdateStore {
    onUpdateStore {
      id
      name
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteStore = /* GraphQL */ `
  subscription OnDeleteStore {
    onDeleteStore {
      id
      name
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUser = /* GraphQL */ `
  subscription OnCreateUser {
    onCreateUser {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      store {
        id
        name
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        createdAt
        updatedAt
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      fuelCells {
        items {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUser = /* GraphQL */ `
  subscription OnUpdateUser {
    onUpdateUser {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      store {
        id
        name
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        createdAt
        updatedAt
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      fuelCells {
        items {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUser = /* GraphQL */ `
  subscription OnDeleteUser {
    onDeleteUser {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      store {
        id
        name
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        createdAt
        updatedAt
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      fuelCells {
        items {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDistributor = /* GraphQL */ `
  subscription OnCreateDistributor {
    onCreateDistributor {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      stores {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDistributor = /* GraphQL */ `
  subscription OnUpdateDistributor {
    onUpdateDistributor {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      stores {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDistributor = /* GraphQL */ `
  subscription OnDeleteDistributor {
    onDeleteDistributor {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      stores {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateDevice = /* GraphQL */ `
  subscription OnCreateDevice {
    onCreateDevice {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateDevice = /* GraphQL */ `
  subscription OnUpdateDevice {
    onUpdateDevice {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteDevice = /* GraphQL */ `
  subscription OnDeleteDevice {
    onDeleteDevice {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateReading = /* GraphQL */ `
  subscription OnCreateReading {
    onCreateReading {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      error
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingSensorId
      readingTreatmentId
      updatedAt
    }
  }
`;
export const onUpdateReading = /* GraphQL */ `
  subscription OnUpdateReading {
    onUpdateReading {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      error
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingSensorId
      readingTreatmentId
      updatedAt
    }
  }
`;
export const onDeleteReading = /* GraphQL */ `
  subscription OnDeleteReading {
    onDeleteReading {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      error
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingSensorId
      readingTreatmentId
      updatedAt
    }
  }
`;
export const onCreateSensor = /* GraphQL */ `
  subscription OnCreateSensor {
    onCreateSensor {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      alert
      high
      low
      email
      phone
      time
      raw
      duration
      monitor
      battery
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          predicate
          duration
          active
          pause
          default
          start
          stop
          type
          timezone
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          createdAt
          updatedAt
        }
        nextToken
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          trigger
          duration
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSensor = /* GraphQL */ `
  subscription OnUpdateSensor {
    onUpdateSensor {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      alert
      high
      low
      email
      phone
      time
      raw
      duration
      monitor
      battery
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          predicate
          duration
          active
          pause
          default
          start
          stop
          type
          timezone
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          createdAt
          updatedAt
        }
        nextToken
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          trigger
          duration
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSensor = /* GraphQL */ `
  subscription OnDeleteSensor {
    onDeleteSensor {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      alert
      high
      low
      email
      phone
      time
      raw
      duration
      monitor
      battery
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          predicate
          duration
          active
          pause
          default
          start
          stop
          type
          timezone
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          createdAt
          updatedAt
        }
        nextToken
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          trigger
          duration
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateFuelCell = /* GraphQL */ `
  subscription OnCreateFuelCell {
    onCreateFuelCell {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      tunnel
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateFuelCell = /* GraphQL */ `
  subscription OnUpdateFuelCell {
    onUpdateFuelCell {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      tunnel
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteFuelCell = /* GraphQL */ `
  subscription OnDeleteFuelCell {
    onDeleteFuelCell {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      tunnel
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTreatment = /* GraphQL */ `
  subscription OnCreateTreatment {
    onCreateTreatment {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      murphyTemps {
        items {
          id
          temp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTreatment = /* GraphQL */ `
  subscription OnUpdateTreatment {
    onUpdateTreatment {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      murphyTemps {
        items {
          id
          temp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTreatment = /* GraphQL */ `
  subscription OnDeleteTreatment {
    onDeleteTreatment {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      murphyTemps {
        items {
          id
          temp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTemp = /* GraphQL */ `
  subscription OnCreateTemp {
    onCreateTemp {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTemp = /* GraphQL */ `
  subscription OnUpdateTemp {
    onUpdateTemp {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTemp = /* GraphQL */ `
  subscription OnDeleteTemp {
    onDeleteTemp {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateEvent = /* GraphQL */ `
  subscription OnCreateEvent {
    onCreateEvent {
      id
      createdAt
      alarm {
        id
        input
        value
        predicate
        duration
        active
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      alerts {
        id
        type
        info
        active
        default
        pause
        nickname
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onUpdateEvent = /* GraphQL */ `
  subscription OnUpdateEvent {
    onUpdateEvent {
      id
      createdAt
      alarm {
        id
        input
        value
        predicate
        duration
        active
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      alerts {
        id
        type
        info
        active
        default
        pause
        nickname
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onDeleteEvent = /* GraphQL */ `
  subscription OnDeleteEvent {
    onDeleteEvent {
      id
      createdAt
      alarm {
        id
        input
        value
        predicate
        duration
        active
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      alerts {
        id
        type
        info
        active
        default
        pause
        nickname
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const onCreateLog = /* GraphQL */ `
  subscription OnCreateLog {
    onCreateLog {
      id
      createdAt
      alarm
      alerts
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      trigger
      duration
      updatedAt
    }
  }
`;
export const onUpdateLog = /* GraphQL */ `
  subscription OnUpdateLog {
    onUpdateLog {
      id
      createdAt
      alarm
      alerts
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      trigger
      duration
      updatedAt
    }
  }
`;
export const onDeleteLog = /* GraphQL */ `
  subscription OnDeleteLog {
    onDeleteLog {
      id
      createdAt
      alarm
      alerts
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      trigger
      duration
      updatedAt
    }
  }
`;
export const onCreateCustomer = /* GraphQL */ `
  subscription OnCreateCustomer {
    onCreateCustomer {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCustomer = /* GraphQL */ `
  subscription OnUpdateCustomer {
    onUpdateCustomer {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCustomer = /* GraphQL */ `
  subscription OnDeleteCustomer {
    onDeleteCustomer {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateAlarm = /* GraphQL */ `
  subscription OnCreateAlarm {
    onCreateAlarm {
      id
      input
      value
      predicate
      duration
      active
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateAlarm = /* GraphQL */ `
  subscription OnUpdateAlarm {
    onUpdateAlarm {
      id
      input
      value
      predicate
      duration
      active
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteAlarm = /* GraphQL */ `
  subscription OnDeleteAlarm {
    onDeleteAlarm {
      id
      input
      value
      predicate
      duration
      active
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCanBus = /* GraphQL */ `
  subscription OnCreateCanBus {
    onCreateCanBus {
      id
      code
      value
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCanBus = /* GraphQL */ `
  subscription OnUpdateCanBus {
    onUpdateCanBus {
      id
      code
      value
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCanBus = /* GraphQL */ `
  subscription OnDeleteCanBus {
    onDeleteCanBus {
      id
      code
      value
      createdAt
      updatedAt
    }
  }
`;
export const onCreateNotification = /* GraphQL */ `
  subscription OnCreateNotification {
    onCreateNotification {
      id
      type
      info
      active
      default
      pause
      nickname
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateNotification = /* GraphQL */ `
  subscription OnUpdateNotification {
    onUpdateNotification {
      id
      type
      info
      active
      default
      pause
      nickname
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteNotification = /* GraphQL */ `
  subscription OnDeleteNotification {
    onDeleteNotification {
      id
      type
      info
      active
      default
      pause
      nickname
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateCode = /* GraphQL */ `
  subscription OnCreateCode {
    onCreateCode {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateCode = /* GraphQL */ `
  subscription OnUpdateCode {
    onUpdateCode {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteCode = /* GraphQL */ `
  subscription OnDeleteCode {
    onDeleteCode {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateTag = /* GraphQL */ `
  subscription OnCreateTag {
    onCreateTag {
      id
      name
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateTag = /* GraphQL */ `
  subscription OnUpdateTag {
    onUpdateTag {
      id
      name
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteTag = /* GraphQL */ `
  subscription OnDeleteTag {
    onDeleteTag {
      id
      name
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSensorTag = /* GraphQL */ `
  subscription OnCreateSensorTag {
    onCreateSensorTag {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSensorTag = /* GraphQL */ `
  subscription OnUpdateSensorTag {
    onUpdateSensorTag {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSensorTag = /* GraphQL */ `
  subscription OnDeleteSensorTag {
    onDeleteSensorTag {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSub = /* GraphQL */ `
  subscription OnCreateSub {
    onCreateSub {
      id
      topic
      deviceId
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSub = /* GraphQL */ `
  subscription OnUpdateSub {
    onUpdateSub {
      id
      topic
      deviceId
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSub = /* GraphQL */ `
  subscription OnDeleteSub {
    onDeleteSub {
      id
      topic
      deviceId
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateUserSub = /* GraphQL */ `
  subscription OnCreateUserSub {
    onCreateUserSub {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateUserSub = /* GraphQL */ `
  subscription OnUpdateUserSub {
    onUpdateUserSub {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteUserSub = /* GraphQL */ `
  subscription OnDeleteUserSub {
    onDeleteUserSub {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onCreateSensorSub = /* GraphQL */ `
  subscription OnCreateSensorSub {
    onCreateSensorSub {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onUpdateSensorSub = /* GraphQL */ `
  subscription OnUpdateSensorSub {
    onUpdateSensorSub {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const onDeleteSensorSub = /* GraphQL */ `
  subscription OnDeleteSensorSub {
    onDeleteSensorSub {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
