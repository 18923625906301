import Vue from 'vue'
import Router from 'vue-router'
import Store from './store/index'
import {
  Auth
} from "aws-amplify"
Vue.use(Router)

const router = new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (savedPosition) {
      return savedPosition
    } else {
      return { x: 0, y: 0 }
    }
  },
  base: process.env.BASE_URL,
  routes: [{
      path: '/',
      name: 'dashboard',
      component: () => import('./views/Dashboard.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/ig160',
      name: 'ig160',
      component: () => import('./views/IG160.vue')
    },
    {
      path: '/device/:id',
      name: 'device',
      component: () => import('./views/Device.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/device/:id/:duelId',
      name: 'duelDevice',
      component: () => import('./views/Device.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/fuelcell',
      name: 'fuelcells',
      component: () => import('./views/FuelCells.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/fuelcell/:id',
      name: 'fuelcell',
      component: () => import('./views/FuelCell.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/treatment/:id',
      name: 'treatment',
      component: () => import('./views/Treatment.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/testing',
      name: 'testing',
      component: () => import('./views/Testing.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/add',
      name: 'addDevice',
      component: () => import('./views/AddDevice.vue'),
      beforeEnter: checkUser
    },
    {
      path: '/add/:id',
      name: 'addSingleDevice',
      component: () => import('./views/AddDevice.vue'),
      beforeEnter: checkUser
    },
    {
      path: '/remove/:id',
      name: 'removeDevice',
      component: () => import('./views/RemoveDevice.vue'),
      beforeEnter: checkRole
    },
    {
      path: '/login',
      name: 'login',
      component: () => import('./views/Login.vue'),
      beforeEnter: redirectUser
    },
    {
      path: '/push',
      name: 'push',
      component: () => import('./views/Push.vue')
    },
    {
      path: '/demo',
      name: 'demo',
      component: () => import('./views/Demo.vue')
    },
    {
      path: '/canbus',
      name: 'canbus',
      component: () => import('./views/CanBus.vue')
    },
    {
      path: '/manage',
      name: 'manage',
      component: () => import('./views/Manage.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/distributors',
      name: 'distributors',
      component: () => import('./views/Distributors.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/dealers',
      name: 'dealers',
      component: () => import('./views/Dealers.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/pending-verification',
      name: 'pending',
      component: () => import('./views/Pending.vue'),
      beforeEnter: requirePending
    },
    {
      path: '/users',
      name: 'users',
      component: () => import('./views/Users.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/owners',
      name: 'owners',
      component: () => import('./views/Stores.vue'),
      beforeEnter: requireAuth
    },
    {
      path: '/locations',
      name: 'locations',
      component: () => import('./views/Locations.vue'),
      beforeEnter: requireAuth
    },
  ]
})

async function requireAuth(to, from, next) {
  if (Store.getters["user/isAuthenticated"]) {
    if (to.name === "dashboard" && Store.getters["user/isFuelCell"]) {
      next("/fuelcell/1")
    }
    else next()
  } else {
    if (await Store.dispatch("user/checkUser")) {
      if (to.name === "dashboard" && Store.getters["user/isFuelCell"]) {
        next("/fuelcell/1")
      }
      else next()
    } else {
      if (from.path.includes("addDevice")) {
        next()
      }
      next("login")
    }
  }
}

async function checkUser(to, from, next) {
  if (Store.getters["user/isAuthenticated"]) {
    next()
  } else {
    if (await Store.dispatch("user/checkUser")) {
      next()
    } else {
      next()
    }
  }
}

async function redirectUser(to, from, next) {
  if (Store.getters["user/isAuthenticated"]) {
    next("/")
  } else {
    if (await Store.dispatch("user/checkUser")) {
      next("/")
    } else {
      next()
    }
  }
}

async function checkRole(to, from, next) {
  if (Store.getters["user/isSuper"]) {
    next()
  } else {
    if (await Store.dispatch("user/checkUserPermission") === "super") {
      next()
    } else {
      next("/")
    }
  }
}

async function requirePending(to, from, next) {
  if (Store.getters["user/isPending"]) {
    next()
  } else {
    next("login")
  }
}

export default router