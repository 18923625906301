import * as queries from "@/graphql/queries";
import * as allMutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import { listLocations } from "@/store/custom"
import {
    API
} from "aws-amplify"

const state = {
    locations: []
}

const getters = {}

const actions = {
    async allLocations({
        commit,
        dispatch
    }) {
        const {
            data: {
                listLocations: {
                    items
                }
            }
        } = await API.graphql({
            query: listLocations,
            variables: {
                limit: 1000,
                filter: {
                    code: {
                        contains: ""
                    }
                }
            }
        })
        commit("setLocations", items.sort((a,b) => a.name > b.name ? 1 : a.name < b.name ? -1 : 0))
    },
    async updateLocation({
        commit,
        dispatch
    }, id) {
        const {
            data: {
                updateLocation
            }
        } = await API.graphql({
            query: allMutations.updateLocation,
            variables: {
                input: {
                    id,
                    locationOwnerId: "76804fe1-f9ee-424a-8cd9-9d94af4b40f5"
                }
            }
        })
        if (updateLocation.id) return true
        else return false
    },
    async updateSensor({
        commit,
        dispatch
    }, sensor) {
        const {
            data: {
                updateSensor
            }
        } = await API.graphql({
            query: allMutations.updateSensor,
            variables: {
                input: {
                    id: sensor.id,
                    sensorLocationId: sensor.location
                }
            }
        })
        if (updateSensor.id) return true
        else return false
    },
    async filterLocations({
        rootGetters
    }, locations) {
        let allLocations = locations.map(item => {
            // if (item.distributors.items.length) {
            //     console.log(item.distributors.items)
            //     item.distributors = item.distributors.items[0].distributor.name
            // } else item.distributors = "NA"
            if (!item.owner) {
                item.owner = "NA"
                item.distributors = "NA"
            } else {
                item.owner = item.owner.name
                item.distributors = "NA"
                // item.distributors = item.owner.distributors.items[0].
            }
            return item
        })
        if (rootGetters["user/isDistributor"]) {
            return allLocations.filter(item => item.distributors === rootGetters["user/userDistributorName"])
        } else return allLocations
    },
}

const mutations = {
    setLocations(state, locations) {
        state.locations = locations
    },
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}