/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const approveUser = /* GraphQL */ `
  mutation ApproveUser($userId: String, $userEmail: String) {
    approveUser(userId: $userId, userEmail: $userEmail)
  }
`;
export const disableUser = /* GraphQL */ `
  mutation DisableUser($userId: String) {
    disableUser(userId: $userId)
  }
`;
export const alert = /* GraphQL */ `
  mutation Alert($sensor: String, $message: String, $connectivity: Boolean) {
    alert(sensor: $sensor, message: $message, connectivity: $connectivity)
  }
`;
export const createLocation = /* GraphQL */ `
  mutation CreateLocation($input: CreateLocationInput!) {
    createLocation(input: $input) {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateLocation = /* GraphQL */ `
  mutation UpdateLocation($input: UpdateLocationInput!) {
    updateLocation(input: $input) {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteLocation = /* GraphQL */ `
  mutation DeleteLocation($input: DeleteLocationInput!) {
    deleteLocation(input: $input) {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createStore = /* GraphQL */ `
  mutation CreateStore($input: CreateStoreInput!) {
    createStore(input: $input) {
      id
      name
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateStore = /* GraphQL */ `
  mutation UpdateStore($input: UpdateStoreInput!) {
    updateStore(input: $input) {
      id
      name
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteStore = /* GraphQL */ `
  mutation DeleteStore($input: DeleteStoreInput!) {
    deleteStore(input: $input) {
      id
      name
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUser = /* GraphQL */ `
  mutation CreateUser($input: CreateUserInput!) {
    createUser(input: $input) {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      store {
        id
        name
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        createdAt
        updatedAt
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      fuelCells {
        items {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUser = /* GraphQL */ `
  mutation UpdateUser($input: UpdateUserInput!) {
    updateUser(input: $input) {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      store {
        id
        name
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        createdAt
        updatedAt
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      fuelCells {
        items {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUser = /* GraphQL */ `
  mutation DeleteUser($input: DeleteUserInput!) {
    deleteUser(input: $input) {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      store {
        id
        name
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        createdAt
        updatedAt
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      fuelCells {
        items {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDistributor = /* GraphQL */ `
  mutation CreateDistributor($input: CreateDistributorInput!) {
    createDistributor(input: $input) {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      stores {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDistributor = /* GraphQL */ `
  mutation UpdateDistributor($input: UpdateDistributorInput!) {
    updateDistributor(input: $input) {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      stores {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDistributor = /* GraphQL */ `
  mutation DeleteDistributor($input: DeleteDistributorInput!) {
    deleteDistributor(input: $input) {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      stores {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createDevice = /* GraphQL */ `
  mutation CreateDevice($input: CreateDeviceInput!) {
    createDevice(input: $input) {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateDevice = /* GraphQL */ `
  mutation UpdateDevice($input: UpdateDeviceInput!) {
    updateDevice(input: $input) {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteDevice = /* GraphQL */ `
  mutation DeleteDevice($input: DeleteDeviceInput!) {
    deleteDevice(input: $input) {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createReading = /* GraphQL */ `
  mutation CreateReading($input: CreateReadingInput!) {
    createReading(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      error
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingSensorId
      readingTreatmentId
      updatedAt
    }
  }
`;
export const updateReading = /* GraphQL */ `
  mutation UpdateReading($input: UpdateReadingInput!) {
    updateReading(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      error
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingSensorId
      readingTreatmentId
      updatedAt
    }
  }
`;
export const deleteReading = /* GraphQL */ `
  mutation DeleteReading($input: DeleteReadingInput!) {
    deleteReading(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      error
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingSensorId
      readingTreatmentId
      updatedAt
    }
  }
`;
export const createSensor = /* GraphQL */ `
  mutation CreateSensor($input: CreateSensorInput!) {
    createSensor(input: $input) {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      alert
      high
      low
      email
      phone
      time
      raw
      duration
      monitor
      battery
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          predicate
          duration
          active
          pause
          default
          start
          stop
          type
          timezone
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          createdAt
          updatedAt
        }
        nextToken
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          trigger
          duration
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSensor = /* GraphQL */ `
  mutation UpdateSensor($input: UpdateSensorInput!) {
    updateSensor(input: $input) {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      alert
      high
      low
      email
      phone
      time
      raw
      duration
      monitor
      battery
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          predicate
          duration
          active
          pause
          default
          start
          stop
          type
          timezone
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          createdAt
          updatedAt
        }
        nextToken
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          trigger
          duration
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSensor = /* GraphQL */ `
  mutation DeleteSensor($input: DeleteSensorInput!) {
    deleteSensor(input: $input) {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      alert
      high
      low
      email
      phone
      time
      raw
      duration
      monitor
      battery
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          predicate
          duration
          active
          pause
          default
          start
          stop
          type
          timezone
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          createdAt
          updatedAt
        }
        nextToken
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          trigger
          duration
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createFuelCell = /* GraphQL */ `
  mutation CreateFuelCell($input: CreateFuelCellInput!) {
    createFuelCell(input: $input) {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      tunnel
      createdAt
      updatedAt
    }
  }
`;
export const updateFuelCell = /* GraphQL */ `
  mutation UpdateFuelCell($input: UpdateFuelCellInput!) {
    updateFuelCell(input: $input) {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      tunnel
      createdAt
      updatedAt
    }
  }
`;
export const deleteFuelCell = /* GraphQL */ `
  mutation DeleteFuelCell($input: DeleteFuelCellInput!) {
    deleteFuelCell(input: $input) {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      tunnel
      createdAt
      updatedAt
    }
  }
`;
export const createTreatment = /* GraphQL */ `
  mutation CreateTreatment($input: CreateTreatmentInput!) {
    createTreatment(input: $input) {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      murphyTemps {
        items {
          id
          temp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTreatment = /* GraphQL */ `
  mutation UpdateTreatment($input: UpdateTreatmentInput!) {
    updateTreatment(input: $input) {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      murphyTemps {
        items {
          id
          temp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTreatment = /* GraphQL */ `
  mutation DeleteTreatment($input: DeleteTreatmentInput!) {
    deleteTreatment(input: $input) {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      murphyTemps {
        items {
          id
          temp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTemp = /* GraphQL */ `
  mutation CreateTemp($input: CreateTempInput!) {
    createTemp(input: $input) {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTemp = /* GraphQL */ `
  mutation UpdateTemp($input: UpdateTempInput!) {
    updateTemp(input: $input) {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTemp = /* GraphQL */ `
  mutation DeleteTemp($input: DeleteTempInput!) {
    deleteTemp(input: $input) {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createEvent = /* GraphQL */ `
  mutation CreateEvent($input: CreateEventInput!) {
    createEvent(input: $input) {
      id
      createdAt
      alarm {
        id
        input
        value
        predicate
        duration
        active
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      alerts {
        id
        type
        info
        active
        default
        pause
        nickname
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const updateEvent = /* GraphQL */ `
  mutation UpdateEvent($input: UpdateEventInput!) {
    updateEvent(input: $input) {
      id
      createdAt
      alarm {
        id
        input
        value
        predicate
        duration
        active
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      alerts {
        id
        type
        info
        active
        default
        pause
        nickname
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const deleteEvent = /* GraphQL */ `
  mutation DeleteEvent($input: DeleteEventInput!) {
    deleteEvent(input: $input) {
      id
      createdAt
      alarm {
        id
        input
        value
        predicate
        duration
        active
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      alerts {
        id
        type
        info
        active
        default
        pause
        nickname
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const createLog = /* GraphQL */ `
  mutation CreateLog($input: CreateLogInput!) {
    createLog(input: $input) {
      id
      createdAt
      alarm
      alerts
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      trigger
      duration
      updatedAt
    }
  }
`;
export const updateLog = /* GraphQL */ `
  mutation UpdateLog($input: UpdateLogInput!) {
    updateLog(input: $input) {
      id
      createdAt
      alarm
      alerts
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      trigger
      duration
      updatedAt
    }
  }
`;
export const deleteLog = /* GraphQL */ `
  mutation DeleteLog($input: DeleteLogInput!) {
    deleteLog(input: $input) {
      id
      createdAt
      alarm
      alerts
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      trigger
      duration
      updatedAt
    }
  }
`;
export const createCustomer = /* GraphQL */ `
  mutation CreateCustomer($input: CreateCustomerInput!) {
    createCustomer(input: $input) {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCustomer = /* GraphQL */ `
  mutation UpdateCustomer($input: UpdateCustomerInput!) {
    updateCustomer(input: $input) {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCustomer = /* GraphQL */ `
  mutation DeleteCustomer($input: DeleteCustomerInput!) {
    deleteCustomer(input: $input) {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createAlarm = /* GraphQL */ `
  mutation CreateAlarm($input: CreateAlarmInput!) {
    createAlarm(input: $input) {
      id
      input
      value
      predicate
      duration
      active
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateAlarm = /* GraphQL */ `
  mutation UpdateAlarm($input: UpdateAlarmInput!) {
    updateAlarm(input: $input) {
      id
      input
      value
      predicate
      duration
      active
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteAlarm = /* GraphQL */ `
  mutation DeleteAlarm($input: DeleteAlarmInput!) {
    deleteAlarm(input: $input) {
      id
      input
      value
      predicate
      duration
      active
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCanBus = /* GraphQL */ `
  mutation CreateCanBus($input: CreateCanBusInput!) {
    createCanBus(input: $input) {
      id
      code
      value
      createdAt
      updatedAt
    }
  }
`;
export const updateCanBus = /* GraphQL */ `
  mutation UpdateCanBus($input: UpdateCanBusInput!) {
    updateCanBus(input: $input) {
      id
      code
      value
      createdAt
      updatedAt
    }
  }
`;
export const deleteCanBus = /* GraphQL */ `
  mutation DeleteCanBus($input: DeleteCanBusInput!) {
    deleteCanBus(input: $input) {
      id
      code
      value
      createdAt
      updatedAt
    }
  }
`;
export const createNotification = /* GraphQL */ `
  mutation CreateNotification($input: CreateNotificationInput!) {
    createNotification(input: $input) {
      id
      type
      info
      active
      default
      pause
      nickname
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateNotification = /* GraphQL */ `
  mutation UpdateNotification($input: UpdateNotificationInput!) {
    updateNotification(input: $input) {
      id
      type
      info
      active
      default
      pause
      nickname
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteNotification = /* GraphQL */ `
  mutation DeleteNotification($input: DeleteNotificationInput!) {
    deleteNotification(input: $input) {
      id
      type
      info
      active
      default
      pause
      nickname
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createCode = /* GraphQL */ `
  mutation CreateCode($input: CreateCodeInput!) {
    createCode(input: $input) {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateCode = /* GraphQL */ `
  mutation UpdateCode($input: UpdateCodeInput!) {
    updateCode(input: $input) {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteCode = /* GraphQL */ `
  mutation DeleteCode($input: DeleteCodeInput!) {
    deleteCode(input: $input) {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createTag = /* GraphQL */ `
  mutation CreateTag($input: CreateTagInput!) {
    createTag(input: $input) {
      id
      name
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateTag = /* GraphQL */ `
  mutation UpdateTag($input: UpdateTagInput!) {
    updateTag(input: $input) {
      id
      name
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteTag = /* GraphQL */ `
  mutation DeleteTag($input: DeleteTagInput!) {
    deleteTag(input: $input) {
      id
      name
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSensorTag = /* GraphQL */ `
  mutation CreateSensorTag($input: CreateSensorTagInput!) {
    createSensorTag(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSensorTag = /* GraphQL */ `
  mutation UpdateSensorTag($input: UpdateSensorTagInput!) {
    updateSensorTag(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSensorTag = /* GraphQL */ `
  mutation DeleteSensorTag($input: DeleteSensorTagInput!) {
    deleteSensorTag(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSub = /* GraphQL */ `
  mutation CreateSub($input: CreateSubInput!) {
    createSub(input: $input) {
      id
      topic
      deviceId
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSub = /* GraphQL */ `
  mutation UpdateSub($input: UpdateSubInput!) {
    updateSub(input: $input) {
      id
      topic
      deviceId
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSub = /* GraphQL */ `
  mutation DeleteSub($input: DeleteSubInput!) {
    deleteSub(input: $input) {
      id
      topic
      deviceId
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const createUserSub = /* GraphQL */ `
  mutation CreateUserSub($input: CreateUserSubInput!) {
    createUserSub(input: $input) {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateUserSub = /* GraphQL */ `
  mutation UpdateUserSub($input: UpdateUserSubInput!) {
    updateUserSub(input: $input) {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteUserSub = /* GraphQL */ `
  mutation DeleteUserSub($input: DeleteUserSubInput!) {
    deleteUserSub(input: $input) {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const createSensorSub = /* GraphQL */ `
  mutation CreateSensorSub($input: CreateSensorSubInput!) {
    createSensorSub(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const updateSensorSub = /* GraphQL */ `
  mutation UpdateSensorSub($input: UpdateSensorSubInput!) {
    updateSensorSub(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const deleteSensorSub = /* GraphQL */ `
  mutation DeleteSensorSub($input: DeleteSensorSubInput!) {
    deleteSensorSub(input: $input) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
