import * as queries from "@/graphql/queries";
import * as allMutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";

import {
    API
} from "aws-amplify"

const state = {
    canbus: "",
    canSub: ""
}

const getters = {}

const actions = {
    async getCanbus({
        commit,
        dispatch
    }) {
        const {
            data: {
                getCanBus
            }
        } = await API.graphql({
            query: queries.getCanBus,
            variables: {
                id: "2bb7b4bb-6d9d-4384-b071-d3d9fc6cc5f1"
            }
        })
        commit("setCanbus", getCanBus)
        dispatch("canbusSubscription")
    },
    canbusSubscription({
        commit,
    }) {
        let sub = API.graphql({
            query: subscriptions.onUpdateCanBus
        }).subscribe({
            next: canData => {
                let {
                    value: {
                        data: {
                            onUpdateCanBus
                        }
                    }
                } = canData;
                commit("setCanbus", onUpdateCanBus)
            }
        });
        commit("setCanbusSubscribe", sub)
    },
    stopCanbusSubscription({
        state
    }) {
        state.canSub.unsubscribe()
    },
}

const mutations = {
    setCanbus(state, val) {
        state.canbus = val
    },
    setCanbusSubscribe(state, sub) {
        state.canSub = sub
    }
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}