import * as queries from "@/graphql/queries";
import * as allMutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import {
    listSensorReadings,
    sortedListSensors,
    distributorSensors,
    ownerSensors,
    listUserSensors,
    listAllSensors,
    listStoreSensors,
    listNotificationsBySensor,
    listAlarmsBySensor,
    dataReadings,
    testingSensors,
    listCustomerSensors,
    listSuperSensors,
    updateSensor,
    getDevice,
    listMDXSensorReadings
} from "@/store/custom"
import {
    API
} from "aws-amplify"

const state = {
    sensor: [],
    sensors: [],
    allSubscribe: "",
    singleSubscribe: "",
    logSubscribe: "",
    sensorSubscribe: "",
    sensorDeleteSubscribe: "",
    deviceType: "",
    tags: [],
    locations: [],
    sensorDetails: "",
    logs: []
}

const getters = {
    tdxSensors(state) {
        return state.sensors.filter(item => {
            if (item.device && item.device.model === "tdx") return true
            else return false
        })
    },
    kdxSensors(state) {
        return state.sensors.filter(item => {
            if (item.device && item.device.model === "C314") return true
            else return false
        })
    },
    mdxSensors(state) {
        return state.sensors.filter(item => {
            if (item.device && item.device.model === "mdx") return true
            else return false
        })
    }
}

const actions = {
    async getStoreSensors({
        commit,
        dispatch
    }, store) {
        const {
            data: {
                listStores: {
                    items
                }
            }
        } = await API.graphql({
            query: listStoreSensors,
            variables: {
                filter: {
                    name: {
                        eq: store
                    }
                }
            }
        })
        commit("setSensors", await dispatch("formatStoreSensors", items))
        dispatch("readingSubscription")
    },
    async getSensorNotifications({
        commit,
        dispatch
    }, uuid) {
        const {
            data: {
                listSensors: {
                    items
                }
            }
        } = await API.graphql({
            query: listNotificationsBySensor,
            variables: {
                filter: {
                    uuid: {
                        eq: uuid
                    }
                },
                limit: 1000
            }
        })
        return items
    },
    async getSensorAlarms({
        commit,
        dispatch
    }, uuid) {
        const {
            data: {
                listSensors: {
                    items
                }
            }
        } = await API.graphql({
            query: listAlarmsBySensor,
            variables: {
                filter: {
                    uuid: {
                        eq: uuid
                    }
                },
                limit: 1000
            }
        })
        return items
    },
    async addNotification({
        commit,
        dispatch
    }, notification) {
        const {
            data: {
                createNotification
            }
        } = await API.graphql({
            query: allMutations.createNotification,
            variables: {
                input: {
                    type: notification.type,
                    info: notification.info,
                    nickname: notification.nickname,
                    notificationSensorId: notification.sensor
                }
            }
        })
        if (createNotification.id) return createNotification
        else return false
    },
    async addAlarm({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                createAlarm
            }
        } = await API.graphql({
            query: allMutations.createAlarm,
            variables: {
                input: {
                    input: alarm.input,
                    value: alarm.value,
                    predicate: alarm.predicate,
                    duration: alarm.duration,
                    alarmSensorId: alarm.alarmSensorId ? alarm.alarmSensorId : alarm.sensor,
                    default: alarm.default ? alarm.default : null,
                    type: alarm.type ? alarm.type : 'value',
                    pause: alarm.pause,
                    timezone: alarm.timezone,
                    start: alarm.start,
                    stop: alarm.stop
                }
            }
        })
        if (createAlarm.id) return createAlarm
        else return false
    },
    async removeNotification({
        commit,
        dispatch
    }, id) {
        const {
            data: {
                deleteNotification
            }
        } = await API.graphql({
            query: allMutations.deleteNotification,
            variables: {
                input: {
                    id
                }
            }
        })
        if (deleteNotification.id) return true
        else return false
    },
    async removeAlarm({
        commit,
        dispatch
    }, id) {
        const {
            data: {
                deleteAlarm
            }
        } = await API.graphql({
            query: allMutations.deleteAlarm,
            variables: {
                input: {
                    id
                }
            }
        })
        if (deleteAlarm.id) return true
        else return false
    },
    async updateAlarmProperty({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    // type: alarm.prop,
                    value: alarm.prop !== "duration" ? alarm.newValue : undefined,
                    duration: alarm.prop === "duration" ? alarm.newValue : undefined
                }
            }
        })
        if (updateAlarm.id) return updateAlarm
        else return false
    },
    async updateAlarmDuration({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    duration: alarm.duration
                }
            }
        })
        if (updateAlarm.id) return updateAlarm
        else return false
    },
    async updateAlarmTemp({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    value: alarm.temp
                }
            }
        })
        if (updateAlarm.id) {
            dispatch("updateSensorTemp", {
                id: alarm.sensorId,
                value: alarm.temp,
                prop: alarm.prop,
            })
        } 
        else return false
    },
    async updateSensorTemp({
        commit,
        dispatch
    }, sensor) {
        const {
            data: {
                updateSensor
            }
        } = await API.graphql({
            query: allMutations.updateSensor,
            variables: {
                input: {
                    id: sensor.id,
                    [sensor.prop]: sensor.value
                }
            }
        })
        if (updateSensor.id) return updateSensor
        else return false
    },
    async updateAlarmHours({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    start: alarm.start,
                    stop: alarm.stop,
                    timezone: alarm.timezone
                }
            }
        })
        if (updateAlarm.id) return updateAlarm
        else return false
    },
    async pauseAlarm({
        commit,
        dispatch
    }, alarm) {
        const {
            data: {
                updateAlarm
            }
        } = await API.graphql({
            query: allMutations.updateAlarm,
            variables: {
                input: {
                    id: alarm.id,
                    pause: alarm.pause
                }
            }
        })
        if (updateAlarm.id) return updateAlarm
        else return false
    },
    async getUserSensors({
        commit,
        dispatch
    }, userId) {
        const {
            data: {
                getUser
            }
        } = await API.graphql({
            query: listUserSensors,
            variables: {
                id: userId
            }
        })
        if (!getUser.store) commit("setSensors", await dispatch("formatUserSensors", [getUser.location]))
        else commit("setSensors", await dispatch("formatUserSensors", getUser.store.locations.items))
        dispatch("readingSubscription")
    },
    async getDemoSensors({
        commit,
        dispatch
    }, deviceId) {
        const {
            data
        } = await API.graphql({
            query: getDevice,
            variables: {
                id: deviceId
            }
        })
        let all = data.getDevice.sensors.items.filter(item => item.name && item.test !== true)
        let filtered = all.sort((a, b) => a.name - b.name)
        commit("setSensors", filtered)
        // dispatch("readingSubscription")
    },
    async getCustomerSensors({
        commit,
        dispatch
    }, userId) {
        const {
            data: {
                getCustomer: {
                    sensors
                }
            }
        } = await API.graphql({
            query: listCustomerSensors,
            variables: {
                id: userId
            }
        })
        if (!sensors.items.length) {
            return false
        } else {
            commit("setSensors", await dispatch("formatCustomerSensors", sensors.items))
            return true
        }
        // if (!getUser.store) commit("setSensors", await dispatch("formatUserSensors", [getUser.location]))
        // else commit("setSensors", await dispatch("formatUserSensors", getUser.store.locations.items))
        // dispatch("readingSubscription")
    },
    async allTags({
        commit
    }) {
        const {
            data: {
                listTags: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.listTags,
            variables: {}
        })
        commit("setTags", items)
    },
    async addTag({
        commit
    }, tagName) {
        const {
            data: {
                createTag
            }
        } = await API.graphql({
            query: allMutations.createTag,
            variables: {
                input: {
                    name: tagName
                }
            }
        })
        commit("addNewTag", createTag)
        return createTag
    },

    async updateSensorProperty({
        commit,
        dispatch
    }, sensor) {
        // console.log(sensor)
        const {
            data: {
                updateSensor
            }
        } = await API.graphql({
            query: allMutations.updateSensor,
            variables: {
                input: {
                    id: sensor.id,
                    [sensor.prop]: sensor.value
                }
            }
        })
        // console.log(updateSensor)
        let allAlarms = updateSensor.alarms.items.filter(item => item.type === sensor.prop)
        if (!sensor.durationChange && allAlarms.length) {
            let [alarm] = updateSensor.alarms.items.filter(item => item.type === sensor.prop)
            await dispatch("updateAlarmProperty", {
                id: alarm.id,
                prop: sensor.prop,
                newValue: sensor.value
            })
            return true
        } else if (sensor.durationChange) {
            let alarms = updateSensor.alarms.items.filter(item => item.value !== null && item.default)
            // console.log(alarms)
            let newAlarms = alarms.map(item => {
                item.prop = sensor.prop
                item.newValue = sensor.value
                return item
            })
            // console.log(newAlarms)
            let finalAlarms = await Promise.all(
                newAlarms.map(async item => {
                    return await dispatch("updateAlarmProperty", item)
                })
            )
            // console.log(finalAlarms)
            return true
        } else if (updateSensor.id) {
            let newAlarm;
            let index = updateSensor.alarms.items.findIndex(item => item.type === sensor.prop)
            if (index === -1) {
                newAlarm = await dispatch("addAlarm", {
                    input: "temp",
                    default: true,
                    type: sensor.prop,
                    value: sensor.value,
                    predicate: sensor.prop === 'low' ? 'lt' : 'gt',
                    duration: sensor.duration,
                    alarmSensorId: sensor.id
                })
                if (newAlarm.id) {
                    return true
                } else return false
            } else {
                let alarm = updateSensor.alarms.items[index]
                alarm.prop = sensor.prop
                alarm.newValue = sensor.value
                newAlarm = await dispatch("updateAlarmProperty", alarm)
                if (newAlarm.id) {
                    return true
                } else return false
            }
        } else {
            return false
        }
    },
    async updateDev({
        commit
    }, sensor) {
        commit("updateSensorMode", sensor)
        const {
            data: {
                updateSensor
            }
        } = await API.graphql({
            query: allMutations.updateSensor,
            variables: {
                input: {
                    id: sensor.id,
                    development: sensor.development
                }
            }
        })
        if (updateSensor.development === sensor.development) return true
        else {
            commit("reverseSensorMode", sensor)
            return false
        }
    },
    async addSensorAlarms({
        dispatch
    }, alarms) {
        let newAlarms = await Promise.all(
            alarms.map(async item => {
                return await dispatch("addAlarm", item)
            })
        )
        return newAlarms
    },
    async addSensorNotifications({
        dispatch
    }, notifications) {
        let newNotifications = await Promise.all(
            notifications.map(async item => {
                return await dispatch("addNotification", item)
            })
        )
        return newNotifications
    },
    async addSensorTag({
        commit
    }, tagProps) {
        const {
            data: {
                createSensorTag
            }
        } = await API.graphql({
            query: allMutations.createSensorTag,
            variables: {
                input: {
                    sensorTagTagId: tagProps.tag,
                    sensorTagSensorId: tagProps.sensor
                }
            }
        })
        commit("addNewSensorTag", createSensorTag)
    },
    async userSubs({
        commit
    }) {

    },
    async addUserSub({
        commit,
        rootState
    }, subId) {
        const {
            data: {
                createUserSub
            }
        } = await API.graphql({
            query: allMutations.createUserSub,
            variables: {
                input: {
                    userSubSubId: subId,
                    userSubUserId: rootState.user.username
                }
            }
        })
        console.log(createUserSub)
        // commit("addNewUserSub", createUserSub)
    },
    async addLocation({
        commit
    }, location) {
        const {
            data: {
                createLocation
            }
        } = await API.graphql({
            query: allMutations.createLocation,
            variables: {
                input: location
            }
        })
        return createLocation.id
    },
    async allLocations({
        commit
    }) {
        const {
            data: {
                listLocations: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.listLocations,
            variables: {}
        })
        commit("setLocations", items)
    },
    async checkLocation({}, locationId) {
        const {
            data: {
                getLocation
            }
        } = await API.graphql({
            query: queries.getLocation,
            variables: {
                id: locationId
            }
        })
        if (getLocation) return getLocation.id
        else return false
    },
    async addSensor({
        state,
        commit,
        getters,
        dispatch
    }, sensor) {
        // check code for userId
        if (sensor.code) {
            let code = await dispatch("checkCode", sensor.code)
            if (!code) return "code not found"
            else {
                sensor.userId = code.user.id
            }
        }
        const {
            data: {
                createSensor
            }
        } = await API.graphql({
            query: allMutations.createSensor,
            variables: {
                input: {
                    uuid: sensor.uuid,
                    name: sensor.name,
                    sensorLocationId: "d00409f7-3b10-464c-ad20-1d4e178c075b",
                    sensorDeviceId: "26b59516-a9fd-43c5-a88d-6fdd973e6911",
                    active: true,
                    development: true,
                    sensorUserId: sensor.userId ? sensor.userId : undefined,
                    duration: sensor.duration,
                    high: sensor.high,
                    low: sensor.low,
                    phone: sensor.phone ? sensor.phone : null,
                    email: sensor.email ? sensor.email : null,
                    raw: sensor.raw ? sensor.raw : null,
                    duel: sensor.duel ? sensor.duel : null,
                    duelId: sensor.duelId ? sensor.duelId : null
                }
            }
        })
        if (createSensor) {
            // add alarms
            let newAlarms = await dispatch("addSensorAlarms", [{
                    default: true,
                    input: "temp",
                    type: "high",
                    value: sensor.high,
                    predicate: "gt",
                    duration: sensor.duration,
                    alarmSensorId: createSensor.id
                },
                {
                    default: true,
                    input: "temp",
                    type: "low",
                    value: sensor.low,
                    predicate: "lt",
                    duration: sensor.duration,
                    alarmSensorId: createSensor.id
                },
                {
                    default: true,
                    type: "connectivity",
                    alarmSensorId: createSensor.id,
                    pause: true
                },
            ])
            // check for phone and email then add notifications
            let notifications = []
            if (sensor.email) {
                notifications.push({
                    type: "email",
                    info: sensor.email,
                    sensor: createSensor.id
                })
            }
            if (sensor.phone) {
                notifications.push({
                    type: "phone",
                    info: sensor.phone,
                    sensor: createSensor.id
                })
            }
            let newNotifications = await dispatch("addSensorNotifications", notifications)
            // commit("addSingleSensor", createSensor)
            return true
        } else return false
    },
    async removeSensor({
        commit,
        state
    }, sensorId) {
        const {
            data: {
                deleteSensor
            }
        } = await API.graphql({
            query: allMutations.deleteSensor,
            variables: {
                input: {
                    id: sensorId
                }
            }
        })
        if (deleteSensor.id) {
            // commit("removeSingleSensor", deleteSensor.id)
            return true
        } else return false

    },
    async removeSensorTag({
        commit
    }, sensorTag) {
        const {
            data: {
                deleteSensorTag
            }
        } = await API.graphql({
            query: allMutations.deleteSensorTag,
            variables: {
                input: {
                    id: sensorTag
                }
            }
        })
        commit("removeTag", deleteSensorTag)
    },
    async removeSensorReadings({
        commit
    }, sensorId) {
        
    },
    async checkCode({
        commit
    }, code) {
        const {
            data: {
                getCode
            }
        } = await API.graphql({
            query: queries.getCode,
            variables: {
                code
            }
        })
        return getCode
    },
    async getSensorReadings({
        commit
    }, sensorId) {
        const {
            data: {
                readingsForSensor: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.readingsForSensor,
            variables: {
                sensorId: sensorId
            }
        })

    },
    async getSensorInfo({
        commit,
        dispatch
    }, sensorUUID) {
        const {
            data: {
                listSensors: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.listSensors,
            variables: {
                filter: {
                    uuid: {
                        eq: sensorUUID
                    }
                },
                limit: 1000
            }
        })
        console.log("info",
            items)
        if (items.length) return items
        else return false
    },
    async singleSensor({
        commit,
        dispatch
    }, sensor) {
        const {
            data: {
                listSensors: {
                    items
                }
            }
        } = await API.graphql({
            query: listSensorReadings,
            variables: {
                filter: {
                    uuid: {
                        eq: sensor.uuid
                    }
                },
                limit: 1000
            }
        })
        let res = items
        if (items.length > 1) {
            res = items.filter(item => item.duelId === sensor.duelId)
        }
        let device
        if (res[0].device.model === "mdx") device = "MDX"
        else if (res[0].device.model === "C314") device = "KDX"
        else if (res[0].device.model === "tdx") device = "TDX"
        let readings = res[0].readings.items.reverse()
        dispatch("setupLogs", {
            id: res[0].id,
            items: res[0].logs.items
        })
        commit("setDeviceType", device)
        commit("setSensorDetails", res[0])
        if (readings.length) {
            commit("setSensor", readings)
            dispatch("singleReadingSubscription", readings[0].readingSensorId)
        }
        return res[0].displayValues
    },
    logSubscription({
        commit,
        state
    }, sensorId) {
        let sub = API.graphql({
            query: subscriptions.onCreateLog
        }).subscribe({
            next: logData => {
                let {
                    value: {
                        data: {
                            onCreateLog
                        }
                    }
                } = logData;
                if (sensorId === onCreateLog.sensor.id) {
                    commit("addLog", onCreateLog);
                    if (onCreateLog.trigger === "offline") {
                        commit("updateSensorDetailsOffline")
                    }
                }
            }
        });
        commit("setLogSubscribe", sub)
    },
    async setupLogs({ dispatch, commit }, logs) {
        commit("setLogs", logs.items)
        dispatch("logSubscription", logs.id)
    },
    async singleMDXSensor({
        commit,
        dispatch
    }, sensorUUID) {
        const {
            data: {
                listSensors: {
                    items
                }
            }
        } = await API.graphql({
            query: listMDXSensorReadings,
            variables: {
                filter: {
                    uuid: {
                        eq: sensorUUID
                    }
                },
                limit: 1000
            }
        })
        let readings = items[0].readings.items.reverse()
        commit("setDeviceType", "MDX")
        commit("setSensorDetails", items[0])
        if (readings.length) {
            commit("setSensor", readings)
            dispatch("singleReadingSubscription", readings[0].readingSensorId)
        }
        return items[0].displayValues
    },
    async getSingleSensor({
        commit,
        dispatch
    }, sensorUUID) {
        const {
            data: {
                listSensors: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.listSensors,
            variables: {
                filter: {
                    uuid: {
                        eq: sensorUUID
                    }
                },
                limit: 1000
            }
        })
        if (items.length) return items[0]
        else return false
    },
    async getDistributorSensors({
        commit,
        dispatch
    }, dist) {
        const {
            data: {
                listDistributors: {
                    items
                }
            }
        } = await API.graphql({
            query: listAllSensors,
            variables: {
                filter: {
                    name: {
                        eq: dist
                    }
                }
            }

        })
        commit("setSensors", await dispatch("formatSensors", items))
        dispatch("readingSubscription")
    },
    async pullReadings({
        commit,
        dispatch,
        state
    }, sensor) {
        let readings = []
        if (sensor.readings) {
            readings = sensor.readings
        }
        const {
            data: {
                checkReadings: {
                    items,
                    nextToken
                }
            }
        } = await API.graphql({
            query: dataReadings,
            variables: {
                sensorId: sensor.id,
                number: sensor.number,
                unit: sensor.unit,
                nextToken: sensor.nextToken ? sensor.nextToken : null,
                from: sensor.from ? sensor.from : null,
                to: sensor.to ? sensor.to : null
            }
        })

        if (nextToken) {
            return dispatch("pullReadings", {
                id: sensor.id,
                number: sensor.number,
                unit: sensor.unit,
                readings: [].concat(...items, ...readings),
                from: sensor.from ? sensor.from : null,
                to: sensor.to ? sensor.to : null,
                nextToken
            })
        } else {
            if (readings.length) {
                var allItems = [].concat(...items, ...readings)
                allItems.sort((a, b) => {
                    return a.createdAt > b.createdAt ? 1 : -1
                })
            }
        }
        if (state.singleSubscribe) {
            dispatch("unsubscribeSingleSensor")
        }

        if (!items.length) return false
        else {
            commit("setSensor", allItems ? allItems : items)
            return allItems ? allItems : items
        }

    },
    async filterSensors({}, sensors) {
        let active = sensors.filter(item => item.active === true && item.readings.items.length)
        let sortedItems = [...active].sort((a, b) => Number(a.label) > Number(b.label) ? 1 : -1)
        return sortedItems.map(item => {
            return {
                id: item.id,
                readings: item.readings.items.length ? item.readings.items : [],
                uuid: item.uuid,
                label: item.label,
                distributor: item.distributor.name,
                owner: item.owner.name,
                tags: item.tags.items.length ? item.tags.items.map(item => {
                    return {
                        id: item.tag.id,
                        name: item.tag.name,
                        sensorTagId: item.id
                    }
                }) : [],
                location: item.location.name
            }
        })
    },
    async formatUserSensors({}, userLocations) {
        let allSensors = []
        userLocations.forEach((item, i) => {
            let location = item
            let sensors = item.sensors.items
            sensors.forEach(item => {
                item.location = location
                item.reading = item.readings.items[0]
            })
            allSensors.push(sensors)
        })
        return ([].concat(...allSensors)).sort((a, b) => Number(a.label) - Number(b.label))
    },
    async formatCustomerSensors({}, sensors) {
        let all = sensors.map(item => {
            item.location = item
            item.reading = item.readings.items[0]
            return item
        })
        return all.sort((a, b) => Number(a.label) - Number(b.label))
    },
    async testingSensors({
        commit,
        dispatch
    }) {
        const {
            data: {
                listSensors: {
                    items
                }
            }
        } = await API.graphql({
            query: testingSensors,
            variables: {
                limit: 1000,
                filter: {
                    test: {
                        eq: true
                    },
                    development: {
                        eq: false
                    }
                }
            }
        })
        items.sort((a, b) => {
            return a.name > b.name ? 1 : a.name < b.name ? -1 : 0
        })
        dispatch("readingSubscription")
        return items
        // commit("setSensors", items)
    },
    async formatStoreSensors({}, stores) {
        let allSensors = []
        stores.forEach((item, i) => {
            let store = item
            let locations = store.locations.items
            locations.forEach((item, i) => {
                let location = item
                let sensors = item.sensors.items
                sensors.forEach(item => {
                    item.store = store
                    item.location = location
                    item.reading = item.readings.items[0]
                })
                allSensors.push(sensors)
            })
        })
        return ([].concat(...allSensors)).sort((a, b) => Number(a.label) - Number(b.label))
    },
    async formatSensors({}, dist) {
        let allSensors = []
        dist.forEach((item, i) => {
            let distributor = item
            let stores = distributor.stores.items
            stores.forEach(item => {
                let store = item
                let locations = store.locations.items
                locations.forEach(item => {
                    let location = item
                    let sensors = item.sensors.items
                    sensors.forEach(item => {
                        item.distributor = distributor
                        item.store = store
                        item.location = location
                        item.reading = item.readings.items[0]
                    })
                    let filtered = sensors.filter(item => item.test !== true)
                    allSensors.push(filtered)
                })
            })
        })
        return ([].concat(...allSensors)).sort((a, b) => Number(a.label) - Number(b.label))
    },
    async allSensors({
        commit,
        dispatch
    }, model) {
        model = "tdx"
        const {
            data: {
                listSensors: {
                    items,
                    nextToken
                }
            }
        } = await API.graphql({
            // query: listAllSensors,
            query: listSuperSensors,
            variables: {
                limit: 500,
                filter: {
                    and: [
                        {
                          test: {
                            ne: true
                          }
                        },
                        {
                          name: {
                            contains: "" 
                          }
                        }
                    ]
                }
            }
        })
        let all = items.filter(item => item.device && item.name && !item.fuelCell)
        // let filtered = all.sort((a, b) => a.name - b.name)
        // commit("setSensors", await dispatch("formatSensors", items))
        commit("setSensors", all)
    },
    sensorSubscription({
        commit,
        state,
        dispatch
    }) {
        let sub = API.graphql({
            query: subscriptions.onUpdateSensor,
            variables: {}
        }).subscribe({
            next: sensorData => {
                let {
                    value: {
                        data: {
                            onUpdateSensor
                        }
                    }
                } = sensorData;
                // console.log(onUpdateSensor)
                if (onUpdateSensor.id) {
                    commit("updateSensor", onUpdateSensor)
                }
            }
        });
        commit("setSensorSubscribe", sub)
    },
    deleteSensorSubscription({
        commit,
        state,
        dispatch
    }) {
        let sub = API.graphql({
            query: subscriptions.onDeleteSensor,
            variables: {}
        }).subscribe({
            next: sensorData => {
                let {
                    value: {
                        data: {
                            onDeleteSensor
                        }
                    }
                } = sensorData;
                if (onDeleteSensor.id) {
                    dispatch("allSensors")
                }
            }
        });
        commit("setDeleteSensorSubscribe", sub)
    },
    readingSubscription({
        commit,
        state,
        dispatch
    }) {
        let sub = API.graphql({
            query: subscriptions.onCreateReading,
            variables: {}
        }).subscribe({
            next: readingData => {
                let {
                    value: {
                        data: {
                            onCreateReading
                        }
                    }
                } = readingData;
                let ind = state.sensors.findIndex(item => item.id === onCreateReading.readingSensorId)
                if (ind === -1) {
                    dispatch("allSensors")
                } else commit("addReading", onCreateReading);

            }
        });
        commit("setSubscribe", sub)
    },
    singleReadingSubscription({
        commit,
        state
    }, sensorId) {
        let sub = API.graphql({
            query: subscriptions.onCreateReading
        }).subscribe({
            next: readingData => {
                let {
                    value: {
                        data: {
                            onCreateReading
                        }
                    }
                } = readingData;
                if (sensorId === onCreateReading.readingSensorId) {
                    commit("addSingleReading", onCreateReading);
                }
            }
        });
        commit("setSingleSubscribe", sub)
    },
    async emptySingleSensor({
        commit,
        state
    }) {
        await state.singleSubscribe.unsubscribe()
        commit("emptySensor")
        commit("removeSingleSubscribe")
    },
    async emptyLogs({
        commit,
        state
    }) {
        await state.logSubscribe.unsubscribe()
        commit("emptySensorLogs")
        commit("removeLogSubscribe")
    },
    async unsubscribeSingleSensor({
        commit,
        state
    }) {
        await state.singleSubscribe.unsubscribe()
        commit("removeSingleSubscribe")
    },
    async unsubscribeSensor({
        commit,
        state
    }) {
        await state.sensorSubscribe.unsubscribe()
        commit("removeSensorSubscribe")
        commit("emptySensors")
    },
    async emptyAllSensors({
        commit,
        state
    }) {
        await state.sensorSubscribe.unsubscribe()
        commit("emptySensors")


        // commit("removeSubscribe")
    }
}

const mutations = {
    setSensor(state, sensor) {
        state.sensor = sensor
    },
    removeSingleSensor(state, sensorId) {
        let index = state.sensors.findIndex(item => item.id === sensorId)
        state.sensors.splice(index, 1)
    },
    setTags(state, tags) {
        state.tags = tags
    },
    addLog(state, log) {
        state.logs.push(log)
    },
    addNewTag(state, tag) {
        state.tags.push(tag)
    },
    removeTag(state, sensorTag) {
        let sensorIndex = state.sensors.findIndex(item => item.id === sensorTag.sensor.id)
        let tagIndex = state.sensors[sensorIndex].tags.findIndex(item => item.id === sensorTag.tag.id)
        state.sensors[sensorIndex].tags.splice(tagIndex, 1)
    },
    addNewSensorTag(state, sensorTag) {
        let index = state.sensors.findIndex(item => item.id === sensorTag.sensor.id)
        state.sensors[index].tags.push({
            id: sensorTag.tag.id,
            name: sensorTag.tag.name,
            sensorTagId: sensorTag.id
        })
    },
    setLocations(state, locations) {
        state.locations = locations
    },
    setSensors(state, sensors) {
        // state.sensors = (state.sensors.concat(...sensors)).sort((a,b) => a.name - b.name)
        state.sensors = state.sensors.concat(...sensors)
    },
    setLogs(state, logs) {
        state.logs = logs
    },
    addReading(state, reading) {
        let index = state.sensors.findIndex(item => item.id === reading.readingSensorId)
        state.sensors[index].reading = reading
    },
    updateSensorDetailsOnline(state) {
        state.sensorDetails.online = true
    },
    updateSensorDetailsOffline(state) {
        state.sensorDetails.online = false
    },
    addSingleReading(state, reading) {
        state.sensor.push(reading)
    },
    addSingleSensor(state, sensor) {
        state.sensors.push(sensor)
    },
    setSensorSubscribe(state, sub) {
        state.sensorSubscribe = sub
    },
    setDeleteSensorSubscribe(state, sub) {
        state.sensorDeleteSubscribe = sub
    },
    setSubscribe(state, sub) {
        state.allSubscribe = sub
    },
    setSingleSubscribe(state, sub) {
        state.singleSubscribe = sub
    },
    setLogSubscribe(state, sub) {
        state.logSubscribe = sub
    },
    emptySensor(state) {
        state.sensor = []
    },
    emptySensors(state) {
        state.sensors = []
    },
    emptySensorLogs(state) {
        state.logs = []
    },
    removeSubscribe(state) {
        state.allSubscribe = ""
    },
    removeSensorSubscribe(state) {
        state.sensorSubscribe = ""
    },
    removeSingleSubscribe(state) {
        state.singleSubscribe = ""
    },
    removeLogSubscribe(state) {
        state.logSubscribe = ""
    },
    updateSensor(state, sensor) {
        let index = state.sensors.findIndex(item => item.id === sensor.id)
        if (index === -1) {
            // console.log("sensor not found", sensor)
            return
        }
        state.sensors.splice(index, 1, sensor)
    },
    updateSensorMode(state, sensor) {
        let index = state.sensors.findIndex(item => item.id === sensor.id)
        state.sensors[index].development = !!sensor.development
    },
    reverseSensorMode(state, sensor) {
        let index = state.sensors.findIndex(item => item.id === sensor.id)
        state.sensors[index].development = !state.sensors[index].development
    },
    setDeviceType(state, type) {
        state.deviceType = type
    },
    setSensorDetails(state, details) {
        state.sensorDetails = details
    }
}

export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}