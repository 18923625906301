import * as queries from "@/graphql/queries";
import * as allMutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import {
    getSingleTreatment,
    getSingleFuelCell,
} from "@/store/custom"
import {
    API
} from "aws-amplify"
import {
    path
} from "ramda"

const state = {
    fuelCells: [],
    fuelCell: {},
    treatment: {},
    singleSubscribe: "",
    sensorsSubscribe: "",
    tempSubscribe: "",
    fuelCellSubscribe: ""
}

const getters = {
    treatments(state) {
        if (!path(["treatments", "items"], state.fuelCell)) {
            return []
        } else return state.fuelCell.treatments.items
    },
    readings(state) {
        if (!path(["readings", "items"], state.treatment)) {
            return []
        } else return state.treatment.readings.items
    },
    sensors(state) {
        if (!path(["sensors", "items"], state.fuelCell)) {
            return []
        } else return state.fuelCell.sensors.items
    },
}

const actions = {
    async allFuelCells({
        commit,
    }) {
        const {
            data: {
                listFuelCells: {
                    items
                }
            }
        } = await API.graphql({
            query: queries.listFuelCells,
            variables: {
                limit: 1000
            }
        })
        commit("setFuelCells", items)
    },
    async singleFuelCell({
        commit,
        dispatch
    }, name) {
        const {
            data: {
                getFuelCell
            }
        } = await API.graphql({
            query: getSingleFuelCell,
            variables: {
                name
            }
        })
        commit("setFuelCell", getFuelCell)
        dispatch("sensorsSubscription")
        dispatch("fuelCellSubscription")
        let treatment = getFuelCell.treatments.items.find(item => !!item.active)
        if (treatment) {
            dispatch("tempSubscription", treatment.id)
        }

    },
    async updateTreatmentAndSensors({
        dispatch,
        commit
    }, req) {
        let updatedSensors = Promise.all(req.sensors.map(async item => {
            return await dispatch("updateSensor", {
                id: item,
                recording: req.action
            })
        }))
        let [first, second] = await Promise.all([dispatch("updateSingleTreatment", {
            id: req.id,
            action: req.action
        }), updatedSensors])
        commit("updateTreatmentActive", first)
        second.map(item => commit("updateSensorRecording", item))
        if (req.action) {
            dispatch("tempSubscription", req.id)
        }
    },
    async updateSingleTreatment({
        commit,
    }, treatment) {
        try {
            const {
                data: {
                    updateTreatment
                }
            } = await API.graphql({
                query: allMutations.updateTreatment,
                variables: {
                    input: {
                        id: treatment.id,
                        active: treatment.action
                    }
                }
            })
            return {
                id: updateTreatment.id,
                active: updateTreatment.active
            }
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async updateSensor({
        commit,
    }, sensor) {
        try {
            const {
                data: {
                    updateSensor
                }
            } = await API.graphql({
                query: allMutations.updateSensor,
                variables: {
                    input: {
                        id: sensor.id,
                        recording: sensor.recording
                    }
                }
            })
            return {
                id: updateSensor.id,
                recording: updateSensor.recording
            }
        } catch (error) {
            console.log(error)
            return false
        }
    },
    async addTreatment({
        commit
    }) {
        try {
            const {
                data: {
                    createTreatment
                }
            } = await API.graphql({
                query: allMutations.createTreatment,
                variables: {
                    input: {
                        treatmentFuelCellId: "1",
                        active: false
                    }
                }
            })
            commit("addSingleTreatment", createTreatment)
        } catch (error) {
            console.log(error)
        }
    },
    async removeTreatment({
        commit
    }, id) {
        try {
            const {
                data: {
                    deleteTreatment
                }
            } = await API.graphql({
                query: allMutations.deleteTreatment,
                variables: {
                    input: {
                        id
                    }
                }
            })
            commit("removeSingleTreatment", deleteTreatment)
        } catch (error) {
            console.log(error)
        }
    },
    async singleTreatment({
        commit,
        dispatch
    }, id) {
        const {
            data: {
                getTreatment
            }
        } = await API.graphql({
            query: getSingleTreatment,
            variables: {
                id
            }
        })
        commit("setTreatment", getTreatment)
        if (getTreatment.active) {
            dispatch("treatmentSubscription", "treatment")
        }
    },
    async deleteSingleReading({
        commit,
    }, reading) {
        try {
            const {
                data: {
                    deleteReading
                }
            } = await API.graphql({
                query: allMutations.deleteReading,
                variables: {
                    input: {
                        id: reading.id,
                        createdAt: reading.createdAt
                    }
                }
            })
            return true
        } catch (error) {
            console.log(error)
            return false
        }
    },
    treatmentSubscription({
        commit,
        state
    }, page) {
        let allSensors
        if (page === "treatment") {
            allSensors = state.treatment.fuelCell.sensors.items.map(item => {
                return {
                    id: item.id,
                    name: item.name
                }
            })
        } else {
            allSensors = state.fuelCell.sensors.items.map(item => {
                return {
                    id: item.id,
                    name: item.name
                }
            })
        }
        let sub = API.graphql({
            query: subscriptions.onCreateReading
        }).subscribe({
            next: readingData => {
                let {
                    value: {
                        data: {
                            onCreateReading
                        }
                    }
                } = readingData;
                let found = allSensors.findIndex(item => {
                    return item.id === onCreateReading.readingSensorId
                })
                if (found !== -1) {
                    // console.log(onCreateReading)
                    // let data = onCreateReading.map(item => {
                    //     return {
                    //         name: item.sensor.name,
                    //         temp: item.min
                    //     }
                    // })
                    // console.log(data)
                    onCreateReading.sensor.name = allSensors[found].name
                    commit("addSingleReading", onCreateReading);
                }
            }
        });
        commit("setSingleSubscribe", sub)
    },
    sensorsSubscription({
        commit,
        state,
    }) {
        let sub = API.graphql({
            query: subscriptions.onUpdateSensor
        }).subscribe({
            next: sensorData => {
                let {
                    value: {
                        data: {
                            onUpdateSensor
                        }
                    }
                } = sensorData;
                let found = state.fuelCell.sensors.items.findIndex(item => {
                    return item.id === onUpdateSensor.id
                })
                if (found !== -1) {
                    let currTreatment = state.fuelCell.treatments.items.filter(item => item.active)
                    if (currTreatment.length && state.fuelCell.sensors.items[found].lastCheckIn !== onUpdateSensor.lastCheckIn) {
                        commit("addCellReading", {})
                    }
                    commit("updateSingleSensor", {
                        index: found,
                        sensor: {
                            id: onUpdateSensor.id,
                            name: onUpdateSensor.name,
                            currentTemp: onUpdateSensor.currentTemp,
                            lastCheckIn: onUpdateSensor.lastCheckIn,
                            recording: onUpdateSensor.recording,
                            online: onUpdateSensor.online
                        }
                    });
                }
            }
        });
        commit("setSensorsSubscribe", sub)
    },
    fuelCellSubscription({
        commit,
        state,
    }) {
        let sub = API.graphql({
            query: subscriptions.onUpdateFuelCell
        }).subscribe({
            next: fuelCellData => {
                let {
                    value: {
                        data: {
                            onUpdateFuelCell
                        }
                    }
                } = fuelCellData;
                commit("updateFuelCell", onUpdateFuelCell)
            }
        });
        commit("setFuelCellSubscribe", sub)
    },
    tempSubscription({
        commit,
        state,
    }, treatmentId) {
        let sub = API.graphql({
            query: subscriptions.onCreateTemp
        }).subscribe({
            next: tempData => {
                let {
                    value: {
                        data: {
                            onCreateTemp
                        }
                    }
                } = tempData;
                if (onCreateTemp.treatment.id === treatmentId) {
                    commit("updateTreatmentTemps", {
                        id: treatmentId,
                        temp: onCreateTemp
                    })
                }
            }
        })
        commit("setTempSubscribe", sub)
    }
}

const mutations = {
    setFuelCells(state, fuelCells) {
        state.fuelCells = fuelCells
    },
    setFuelCell(state, fuelCell) {
        state.fuelCell = fuelCell
    },
    setTreatment(state, treatment) {
        state.treatment = treatment
    },
    setSingleSubscribe(state, sub) {
        state.singleSubscribe = sub
    },
    setFuelCellSubscribe(state, sub) {
        state.fuelCellSubscribe = sub
    },
    setSensorsSubscribe(state, sub) {
        state.sensorsSubscribe = sub
    },
    setTempSubscribe(state, sub) {
        state.tempSubscribe = sub
    },
    addSingleReading(state, reading) {
        state.treatment.readings.items.push(reading)
    },
    addSingleTreatment(state, treatment) {
        state.fuelCell.treatments.items.push(treatment)
    },
    removeSingleTreatment(state, treatment) {
        let find = state.fuelCell.treatments.items.findIndex(item => item.id === treatment.id)
        state.fuelCell.treatments.items.splice(find, 1)
    },
    addCellReading(state, reading) {
        let find = state.fuelCell.treatments.items.findIndex(item => item.active)
        state.fuelCell.treatments.items[find].readings.items.push(reading)
    },
    updateSingleSensor(state, sensor) {
        state.fuelCell.sensors.items.splice(sensor.index, 1, sensor.sensor)
    },
    updateFuelCell(state, fuelCell) {
        state.fuelCell.online = fuelCell.online
        state.fuelCell.lastUpdate = fuelCell.lastUpdate
        state.fuelCell.water = fuelCell.water
        state.fuelCell.fuel = fuelCell.fuel
    },
    updateSensorRecording(state, sensor) {
        let find = state.fuelCell.sensors.items.findIndex(item => item.id === sensor.id)
        state.fuelCell.sensors.items[find].recording = sensor.recording
    },
    updateTreatmentActive(state, treatment) {
        let find = state.fuelCell.treatments.items.findIndex(item => item.id === treatment.id)
        state.fuelCell.treatments.items[find].active = treatment.active
    },
    updateTreatmentTemps(state, treatment) {
        let find = state.fuelCell.treatments.items.findIndex(item => item.id === treatment.id)
        state.fuelCell.treatments.items[find].murphyTemps.items.push(treatment.temp)
    },
    emptyReadings(state) {
        state.treatment.readings.items = []
    }
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}