/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const readingsForSensor = /* GraphQL */ `
  query ReadingsForSensor($sensorId: ID!, $limit: Int, $nextToken: String) {
    readingsForSensor(
      sensorId: $sensorId
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        treatment {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        createdAt
        error
        temp1
        temp2
        temp3
        temp4
        voltage1
        voltage2
        voltage3
        pressure1
        pressure2
        pressure3
        pressure4
        pressure5
        pressure6
        min
        min2
        am
        b1Syrup
        b2Syrup
        b3Syrup
        b4Syrup
        b1SyrupOut
        b2SyrupOut
        b3SyrupOut
        b4SyrupOut
        b1Water
        b2Water
        b3Water
        b4Water
        b1Defrost
        b2Defrost
        b3Defrost
        b4Defrost
        b1Liquid
        b2Liquid
        b3Liquid
        b4Liquid
        b1Fill
        b2Fill
        b3Fill
        b4Fill
        b1BIB
        b2BIB
        b3BIB
        b4BIB
        b1C02
        b2C02
        b3C02
        b4C02
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        ambient
        H20Pressure
        C02Pressure
        leftHighPressureCoil
        rightHighPressureCoil
        compSuctionTemp
        compReturnTemp
        compCut
        leftCompContCoil
        rightCompContCoil
        testSignal
        testAcknowledge
        gnd1
        gnd2
        gnd3
        gnd4
        ACReturn
        ACIn
        readingSensorId
        readingTreatmentId
        updatedAt
      }
      nextToken
    }
  }
`;
export const checkReadings = /* GraphQL */ `
  query CheckReadings(
    $sensorId: String
    $number: Int
    $unit: Unit
    $temp: Int
    $operator: String
    $from: String
    $to: String
    $nextToken: String
  ) {
    checkReadings(
      sensorId: $sensorId
      number: $number
      unit: $unit
      temp: $temp
      operator: $operator
      from: $from
      to: $to
      nextToken: $nextToken
    ) {
      items {
        id
        createdAt
        min
        min2
        temp1
        temp2
        temp3
        temp4
        pressure1
        pressure2
        pressure3
        pressure4
        pressure5
        pressure6
        voltage1
        voltage2
        voltage3
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        readingSensorId
        readingTreatmentId
      }
      nextToken
    }
  }
`;
export const checkConnectivity = /* GraphQL */ `
  query CheckConnectivity {
    checkConnectivity {
      id
      name
      label
      online
      lastCheckIn
      lastReading {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        treatment {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        createdAt
        error
        temp1
        temp2
        temp3
        temp4
        voltage1
        voltage2
        voltage3
        pressure1
        pressure2
        pressure3
        pressure4
        pressure5
        pressure6
        min
        min2
        am
        b1Syrup
        b2Syrup
        b3Syrup
        b4Syrup
        b1SyrupOut
        b2SyrupOut
        b3SyrupOut
        b4SyrupOut
        b1Water
        b2Water
        b3Water
        b4Water
        b1Defrost
        b2Defrost
        b3Defrost
        b4Defrost
        b1Liquid
        b2Liquid
        b3Liquid
        b4Liquid
        b1Fill
        b2Fill
        b3Fill
        b4Fill
        b1BIB
        b2BIB
        b3BIB
        b4BIB
        b1C02
        b2C02
        b3C02
        b4C02
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        ambient
        H20Pressure
        C02Pressure
        leftHighPressureCoil
        rightHighPressureCoil
        compSuctionTemp
        compReturnTemp
        compCut
        leftCompContCoil
        rightCompContCoil
        testSignal
        testAcknowledge
        gnd1
        gnd2
        gnd3
        gnd4
        ACReturn
        ACIn
        readingSensorId
        readingTreatmentId
        updatedAt
      }
    }
  }
`;
export const getLocation = /* GraphQL */ `
  query GetLocation($id: ID!) {
    getLocation(id: $id) {
      id
      name
      address
      city
      state
      zip
      code
      owner {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      users {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listLocations = /* GraphQL */ `
  query ListLocations(
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLocations(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getStore = /* GraphQL */ `
  query GetStore($id: ID!) {
    getStore(id: $id) {
      id
      name
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listStores = /* GraphQL */ `
  query ListStores(
    $filter: ModelStoreFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listStores(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUser = /* GraphQL */ `
  query GetUser($id: ID!) {
    getUser(id: $id) {
      id
      first
      last
      email
      phone
      code
      approved
      distributor {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      store {
        id
        name
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        createdAt
        updatedAt
      }
      auth
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      admin {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      admins {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      locations {
        items {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        nextToken
      }
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      type
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      fuelCells {
        items {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUsers = /* GraphQL */ `
  query ListUsers(
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDistributor = /* GraphQL */ `
  query GetDistributor($id: ID!) {
    getDistributor(id: $id) {
      id
      name
      owners {
        items {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      stores {
        items {
          id
          name
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDistributors = /* GraphQL */ `
  query ListDistributors(
    $filter: ModelDistributorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDistributors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        owners {
          nextToken
        }
        stores {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getDevice = /* GraphQL */ `
  query GetDevice($id: ID!) {
    getDevice(id: $id) {
      id
      model
      manufacturer
      test
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listDevices = /* GraphQL */ `
  query ListDevices(
    $filter: ModelDeviceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listDevices(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        model
        manufacturer
        test
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getReading = /* GraphQL */ `
  query GetReading($id: ID!, $createdAt: AWSDateTime!) {
    getReading(id: $id, createdAt: $createdAt) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      error
      temp1
      temp2
      temp3
      temp4
      voltage1
      voltage2
      voltage3
      pressure1
      pressure2
      pressure3
      pressure4
      pressure5
      pressure6
      min
      min2
      am
      b1Syrup
      b2Syrup
      b3Syrup
      b4Syrup
      b1SyrupOut
      b2SyrupOut
      b3SyrupOut
      b4SyrupOut
      b1Water
      b2Water
      b3Water
      b4Water
      b1Defrost
      b2Defrost
      b3Defrost
      b4Defrost
      b1Liquid
      b2Liquid
      b3Liquid
      b4Liquid
      b1Fill
      b2Fill
      b3Fill
      b4Fill
      b1BIB
      b2BIB
      b3BIB
      b4BIB
      b1C02
      b2C02
      b3C02
      b4C02
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      ambient
      H20Pressure
      C02Pressure
      leftHighPressureCoil
      rightHighPressureCoil
      compSuctionTemp
      compReturnTemp
      compCut
      leftCompContCoil
      rightCompContCoil
      testSignal
      testAcknowledge
      gnd1
      gnd2
      gnd3
      gnd4
      ACReturn
      ACIn
      readingSensorId
      readingTreatmentId
      updatedAt
    }
  }
`;
export const listReadings = /* GraphQL */ `
  query ListReadings(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelReadingFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listReadings(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        treatment {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        createdAt
        error
        temp1
        temp2
        temp3
        temp4
        voltage1
        voltage2
        voltage3
        pressure1
        pressure2
        pressure3
        pressure4
        pressure5
        pressure6
        min
        min2
        am
        b1Syrup
        b2Syrup
        b3Syrup
        b4Syrup
        b1SyrupOut
        b2SyrupOut
        b3SyrupOut
        b4SyrupOut
        b1Water
        b2Water
        b3Water
        b4Water
        b1Defrost
        b2Defrost
        b3Defrost
        b4Defrost
        b1Liquid
        b2Liquid
        b3Liquid
        b4Liquid
        b1Fill
        b2Fill
        b3Fill
        b4Fill
        b1BIB
        b2BIB
        b3BIB
        b4BIB
        b1C02
        b2C02
        b3C02
        b4C02
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        ambient
        H20Pressure
        C02Pressure
        leftHighPressureCoil
        rightHighPressureCoil
        compSuctionTemp
        compReturnTemp
        compCut
        leftCompContCoil
        rightCompContCoil
        testSignal
        testAcknowledge
        gnd1
        gnd2
        gnd3
        gnd4
        ACReturn
        ACIn
        readingSensorId
        readingTreatmentId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSensor = /* GraphQL */ `
  query GetSensor($id: ID!) {
    getSensor(id: $id) {
      id
      uuid
      label
      name
      duel
      duelId
      serialNumber
      oem
      harnessNumber
      partNumber
      development
      downlink
      test
      version
      online
      currentTemp
      currentTemp2
      t1
      t2
      t3
      t4
      v1
      v2
      v3
      p1
      p2
      p3
      p4
      p5
      p6
      b1Temp
      b2Temp
      b3Temp
      b4Temp
      compSuctionTemp
      compReturnTemp
      ambient
      displayValues
      code
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      recording
      location {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      locationNote
      sensorLocationId
      device {
        id
        model
        manufacturer
        test
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      tags {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      active
      alert
      high
      low
      email
      phone
      time
      raw
      duration
      monitor
      battery
      subs {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      notes
      lastCheckIn
      alarms {
        items {
          id
          input
          value
          predicate
          duration
          active
          pause
          default
          start
          stop
          type
          timezone
          createdAt
          updatedAt
        }
        nextToken
      }
      notifications {
        items {
          id
          type
          info
          active
          default
          pause
          nickname
          createdAt
          updatedAt
        }
        nextToken
      }
      customer {
        id
        first
        last
        email
        phone
        approved
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      events {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      logs {
        items {
          id
          createdAt
          alarm
          alerts
          trigger
          duration
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSensors = /* GraphQL */ `
  query ListSensors(
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensors(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFuelCell = /* GraphQL */ `
  query GetFuelCell($name: String!) {
    getFuelCell(name: $name) {
      name
      treatments {
        items {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      online
      lastUpdate
      fuel
      water
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      tunnel
      createdAt
      updatedAt
    }
  }
`;
export const listFuelCells = /* GraphQL */ `
  query ListFuelCells(
    $name: String
    $filter: ModelFuelCellFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listFuelCells(
      name: $name
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTreatment = /* GraphQL */ `
  query GetTreatment($id: ID!) {
    getTreatment(id: $id) {
      id
      tag
      active
      start
      end
      fuelCell {
        name
        treatments {
          nextToken
        }
        sensors {
          nextToken
        }
        online
        lastUpdate
        fuel
        water
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        tunnel
        createdAt
        updatedAt
      }
      readings {
        items {
          id
          createdAt
          error
          temp1
          temp2
          temp3
          temp4
          voltage1
          voltage2
          voltage3
          pressure1
          pressure2
          pressure3
          pressure4
          pressure5
          pressure6
          min
          min2
          am
          b1Syrup
          b2Syrup
          b3Syrup
          b4Syrup
          b1SyrupOut
          b2SyrupOut
          b3SyrupOut
          b4SyrupOut
          b1Water
          b2Water
          b3Water
          b4Water
          b1Defrost
          b2Defrost
          b3Defrost
          b4Defrost
          b1Liquid
          b2Liquid
          b3Liquid
          b4Liquid
          b1Fill
          b2Fill
          b3Fill
          b4Fill
          b1BIB
          b2BIB
          b3BIB
          b4BIB
          b1C02
          b2C02
          b3C02
          b4C02
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          ambient
          H20Pressure
          C02Pressure
          leftHighPressureCoil
          rightHighPressureCoil
          compSuctionTemp
          compReturnTemp
          compCut
          leftCompContCoil
          rightCompContCoil
          testSignal
          testAcknowledge
          gnd1
          gnd2
          gnd3
          gnd4
          ACReturn
          ACIn
          readingSensorId
          readingTreatmentId
          updatedAt
        }
        nextToken
      }
      murphyTemps {
        items {
          id
          temp
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTreatments = /* GraphQL */ `
  query ListTreatments(
    $filter: ModelTreatmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTreatments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTemp = /* GraphQL */ `
  query GetTemp($id: ID!) {
    getTemp(id: $id) {
      id
      temp
      treatment {
        id
        tag
        active
        start
        end
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        murphyTemps {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTemps = /* GraphQL */ `
  query ListTemps(
    $filter: ModelTempFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTemps(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        temp
        treatment {
          id
          tag
          active
          start
          end
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getEvent = /* GraphQL */ `
  query GetEvent($id: ID!, $createdAt: AWSDateTime!) {
    getEvent(id: $id, createdAt: $createdAt) {
      id
      createdAt
      alarm {
        id
        input
        value
        predicate
        duration
        active
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      alerts {
        id
        type
        info
        active
        default
        pause
        nickname
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      updatedAt
    }
  }
`;
export const listEvents = /* GraphQL */ `
  query ListEvents(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelEventFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listEvents(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        alarm {
          id
          input
          value
          predicate
          duration
          active
          pause
          default
          start
          stop
          type
          timezone
          createdAt
          updatedAt
        }
        alerts {
          id
          type
          info
          active
          default
          pause
          nickname
          createdAt
          updatedAt
        }
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        updatedAt
      }
      nextToken
    }
  }
`;
export const getLog = /* GraphQL */ `
  query GetLog($id: ID!, $createdAt: AWSDateTime!) {
    getLog(id: $id, createdAt: $createdAt) {
      id
      createdAt
      alarm
      alerts
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      trigger
      duration
      updatedAt
    }
  }
`;
export const listLogs = /* GraphQL */ `
  query ListLogs(
    $id: ID
    $createdAt: ModelStringKeyConditionInput
    $filter: ModelLogFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listLogs(
      id: $id
      createdAt: $createdAt
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        id
        createdAt
        alarm
        alerts
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        trigger
        duration
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCustomer = /* GraphQL */ `
  query GetCustomer($id: ID!) {
    getCustomer(id: $id) {
      id
      first
      last
      email
      phone
      approved
      sensors {
        items {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCustomers = /* GraphQL */ `
  query ListCustomers(
    $filter: ModelCustomerFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCustomers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        first
        last
        email
        phone
        approved
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getAlarm = /* GraphQL */ `
  query GetAlarm($id: ID!) {
    getAlarm(id: $id) {
      id
      input
      value
      predicate
      duration
      active
      pause
      default
      start
      stop
      type
      timezone
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAlarms = /* GraphQL */ `
  query ListAlarms(
    $filter: ModelAlarmFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAlarms(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        input
        value
        predicate
        duration
        active
        pause
        default
        start
        stop
        type
        timezone
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCanBus = /* GraphQL */ `
  query GetCanBus($id: ID!) {
    getCanBus(id: $id) {
      id
      code
      value
      createdAt
      updatedAt
    }
  }
`;
export const listCanBuss = /* GraphQL */ `
  query ListCanBuss(
    $filter: ModelCanBusFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCanBuss(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        code
        value
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getNotification = /* GraphQL */ `
  query GetNotification($id: ID!) {
    getNotification(id: $id) {
      id
      type
      info
      active
      default
      pause
      nickname
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listNotifications = /* GraphQL */ `
  query ListNotifications(
    $filter: ModelNotificationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listNotifications(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        info
        active
        default
        pause
        nickname
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getCode = /* GraphQL */ `
  query GetCode($code: String!) {
    getCode(code: $code) {
      code
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listCodes = /* GraphQL */ `
  query ListCodes(
    $code: String
    $filter: ModelCodeFilterInput
    $limit: Int
    $nextToken: String
    $sortDirection: ModelSortDirection
  ) {
    listCodes(
      code: $code
      filter: $filter
      limit: $limit
      nextToken: $nextToken
      sortDirection: $sortDirection
    ) {
      items {
        code
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTag = /* GraphQL */ `
  query GetTag($id: ID!) {
    getTag(id: $id) {
      id
      name
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTags = /* GraphQL */ `
  query ListTags(
    $filter: ModelTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSensorTag = /* GraphQL */ `
  query GetSensorTag($id: ID!) {
    getSensorTag(id: $id) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      tag {
        id
        name
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSensorTags = /* GraphQL */ `
  query ListSensorTags(
    $filter: ModelSensorTagFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensorTags(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        tag {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSub = /* GraphQL */ `
  query GetSub($id: ID!) {
    getSub(id: $id) {
      id
      topic
      deviceId
      users {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      sensors {
        items {
          id
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSubs = /* GraphQL */ `
  query ListSubs(
    $filter: ModelSubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserSub = /* GraphQL */ `
  query GetUserSub($id: ID!) {
    getUserSub(id: $id) {
      id
      user {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listUserSubs = /* GraphQL */ `
  query ListUserSubs(
    $filter: ModelUserSubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserSubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sub {
          id
          topic
          deviceId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getSensorSub = /* GraphQL */ `
  query GetSensorSub($id: ID!) {
    getSensorSub(id: $id) {
      id
      sensor {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      sub {
        id
        topic
        deviceId
        users {
          nextToken
        }
        sensors {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listSensorSubs = /* GraphQL */ `
  query ListSensorSubs(
    $filter: ModelSensorSubFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSensorSubs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        sensor {
          id
          uuid
          label
          name
          duel
          duelId
          serialNumber
          oem
          harnessNumber
          partNumber
          development
          downlink
          test
          version
          online
          currentTemp
          currentTemp2
          t1
          t2
          t3
          t4
          v1
          v2
          v3
          p1
          p2
          p3
          p4
          p5
          p6
          b1Temp
          b2Temp
          b3Temp
          b4Temp
          compSuctionTemp
          compReturnTemp
          ambient
          displayValues
          code
          recording
          locationNote
          sensorLocationId
          active
          alert
          high
          low
          email
          phone
          time
          raw
          duration
          monitor
          battery
          notes
          lastCheckIn
          createdAt
          updatedAt
        }
        sub {
          id
          topic
          deviceId
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const locationByCode = /* GraphQL */ `
  query LocationByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelLocationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    locationByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        address
        city
        state
        zip
        code
        owner {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        sensors {
          nextToken
        }
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const userByCode = /* GraphQL */ `
  query UserByCode(
    $code: String
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    userByCode(
      code: $code
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        first
        last
        email
        phone
        code
        approved
        distributor {
          id
          name
          createdAt
          updatedAt
        }
        store {
          id
          name
          createdAt
          updatedAt
        }
        auth
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        admin {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        admins {
          nextToken
        }
        locations {
          nextToken
        }
        subs {
          nextToken
        }
        type
        sensors {
          nextToken
        }
        fuelCells {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const sensorByUuid = /* GraphQL */ `
  query SensorByUuid(
    $uuid: String
    $sortDirection: ModelSortDirection
    $filter: ModelSensorFilterInput
    $limit: Int
    $nextToken: String
  ) {
    sensorByUUID(
      uuid: $uuid
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        uuid
        label
        name
        duel
        duelId
        serialNumber
        oem
        harnessNumber
        partNumber
        development
        downlink
        test
        version
        online
        currentTemp
        currentTemp2
        t1
        t2
        t3
        t4
        v1
        v2
        v3
        p1
        p2
        p3
        p4
        p5
        p6
        b1Temp
        b2Temp
        b3Temp
        b4Temp
        compSuctionTemp
        compReturnTemp
        ambient
        displayValues
        code
        fuelCell {
          name
          online
          lastUpdate
          fuel
          water
          tunnel
          createdAt
          updatedAt
        }
        recording
        location {
          id
          name
          address
          city
          state
          zip
          code
          createdAt
          updatedAt
        }
        locationNote
        sensorLocationId
        device {
          id
          model
          manufacturer
          test
          createdAt
          updatedAt
        }
        readings {
          nextToken
        }
        tags {
          nextToken
        }
        active
        alert
        high
        low
        email
        phone
        time
        raw
        duration
        monitor
        battery
        subs {
          nextToken
        }
        notes
        lastCheckIn
        alarms {
          nextToken
        }
        notifications {
          nextToken
        }
        customer {
          id
          first
          last
          email
          phone
          approved
          createdAt
          updatedAt
        }
        user {
          id
          first
          last
          email
          phone
          code
          approved
          auth
          type
          createdAt
          updatedAt
        }
        events {
          nextToken
        }
        logs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
