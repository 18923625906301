import Vue from 'vue'
import Vuex from 'vuex'
import sensor from './modules/sensor'
import user from './modules/user'
import store from './modules/store'
import location from './modules/location'
import canbus from './modules/canbus'
import fuelcell from './modules/fuelcell'

Vue.use(Vuex)

export default new Vuex.Store({
    modules: {
        sensor,
        user,
        store,
        location,
        canbus,
        fuelcell
    }
})