import * as queries from "@/graphql/queries";
import * as allMutations from "@/graphql/mutations";
import * as subscriptions from "@/graphql/subscriptions";
import {
    listDistributorOwners
} from "@/store/custom"
import {
    API
} from "aws-amplify"

const state = {
    owners: []
}

const getters = {}

const actions = {
    // async allOwners({
    //     commit,
    //     dispatch
    // }) {
    //     const {
    //         data: {
    //             listOwners: {
    //                 items
    //             }
    //         }
    //     } = await API.graphql({
    //         query: queries.listOwners,
    //         variables: {
    //             limit: 1000
    //         }
    //     })
    //     commit("setOwners", await dispatch("filterOwners", items))
    // },
    // async getDistributorOwners({
    //     commit,
    //     dispatch
    // }, dist) {
    //     const {
    //         data: {
    //             listOwners: {
    //                 items
    //             }
    //         }
    //     } = await API.graphql({
    //         query: queries.listOwners,
    //         variables: {
    //             limit: 1000
    //         }
    //     })
    //     commit("setOwners", await dispatch("filterOwners", items))
    // },
    async filterOwners({
        rootGetters
    }, owners) {
        let allOwners = owners.map(item => {
            if (item.distributors.items.length) {
                item.distributors = item.distributors.items[0].distributor.name
            } else item.distributors = "NA"
            if (!item.owner) item.owner = "NA"
            if (item.sensors.items.length) {
                item.sensors = item.sensors.items
            } else item.sensors = "NA"
            return item
        })
        if (rootGetters["user/isDistributor"]) {
            return allOwners.filter(item => item.distributors === rootGetters["user/userDistributorName"])
        } else return allOwners
    },
}

const mutations = {
    setOwners(state, owners) {
        state.owners = owners
    },
}



export default {
    namespaced: true,
    state,
    getters,
    actions,
    mutations
}