/* eslint-disable */
// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsmobile = {
    "aws_project_region": "us-east-1",
    "aws_appsync_graphqlEndpoint": "https://kgj2es3ocrhpdffms4toitwpza.appsync-api.us-east-1.amazonaws.com/graphql",
    "aws_appsync_region": "us-east-1",
    "aws_appsync_authenticationType": "API_KEY",
    "aws_appsync_apiKey": "da2-fbzlwczwj5b4ppuwfckybs3xhm",
    "aws_cloud_logic_custom": [
        {
            "name": "bwrEndpoint",
            "endpoint": "https://8mzg8o5i11.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        },
        {
            "name": "bwrDigiEndpoint",
            "endpoint": "https://fxksqcjux1.execute-api.us-east-1.amazonaws.com/test",
            "region": "us-east-1"
        }
    ],
    "aws_content_delivery_bucket": "bwr-dashboard-test",
    "aws_content_delivery_bucket_region": "us-east-1",
    "aws_content_delivery_url": "https://d23r724uymdxo7.cloudfront.net",
    "aws_cognito_identity_pool_id": "us-east-1:2b7872e2-ccf8-490e-93d5-2cde3dc8fc67",
    "aws_cognito_region": "us-east-1",
    "aws_user_pools_id": "us-east-1_bLa1gi3Pg",
    "aws_user_pools_web_client_id": "1nkl885tbad57bhovu3ap168bb",
    "oauth": {},
    "aws_cognito_username_attributes": [
        "EMAIL"
    ],
    "aws_cognito_social_providers": [],
    "aws_cognito_signup_attributes": [
        "EMAIL",
        "FAMILY_NAME",
        "GIVEN_NAME"
    ],
    "aws_cognito_mfa_configuration": "OFF",
    "aws_cognito_mfa_types": [],
    "aws_cognito_password_protection_settings": {
        "passwordPolicyMinLength": 8,
        "passwordPolicyCharacters": []
    },
    "aws_cognito_verification_mechanisms": [
        "EMAIL"
    ]
};


export default awsmobile;
